import { LocalizedLink } from 'gatsby-theme-i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useElementOnScreen from '../../utils/useElementOnScreen';

type AllExpertisesProps = {
  showTitle?: boolean;
  scrollSnap?: boolean;
  children?: React.ReactNode;
};

type Expertise = {
  id: string;
  title: string;
  subtitle: string;
};

type Offer = {
  id: string;
  expertises: Expertise[];
};

const animationDelays = ['', 'animate-delay-400', 'animate-delay-800', 'animate-delay-1200'];

const AllExpertises: React.FC<AllExpertisesProps> = ({
  showTitle = false,
  scrollSnap = false,
  children,
}) => {
  const { t } = useTranslation('expertises');

  const content = React.useMemo(() => {
    const result: Offer[] = [];
    const offers = t<string, { [key: string]: Record<string, Expertise> }>('expertises', {
      returnObjects: true,
      defaultValue: {},
    });
    Object.keys(offers).forEach((offerKey) => {
      const offer = offers[offerKey];
      const expertises: Expertise[] = [];
      Object.keys(offer).forEach((key) => {
        const expertise = offer[key];
        expertise.id = key;
        expertises.push(expertise);
      });
      result.push({ id: offerKey, expertises });
    });
    return result;
  }, [t]);

  const [animatedElement, isInView] = useElementOnScreen<HTMLDivElement>();

  return (
    <section id="expertises-section">
      {children}
      <div
        id="expertises"
        className={`overflow-hidden bg-black text-white ${showTitle ? '' : 'h-lg:lg:h-screen'} ${
          scrollSnap ? 'lg:snap-start' : ''
        }`}
      >
        <div
          className={`container h-full px-4 pb-4 pt-8 md:pl-8 lg:pl-12 ${
            showTitle ? 'lg:pb-8' : 'h-lg:lg:pt-0'
          } flex flex-col justify-center`}
        >
          {showTitle && <h3 className="mb-12">{t('discover-all-our-expertises')}</h3>}
          <div className="flex w-full flex-wrap justify-between" ref={animatedElement}>
            {content.map((offer, idx) => (
              <div
                key={offer.id}
                className={`mb-4 w-full translate-y-12 opacity-0 sm:px-2 md:-mx-1 md:w-1/2 md:px-0 ${
                  animationDelays[idx]
                } ${isInView ? 'animate-slidein' : ''}`}
              >
                {offer.expertises.map((expertise) => (
                  <LocalizedLink
                    to={`/expertises/${expertise.id}`}
                    key={expertise.id}
                    className="block"
                  >
                    <div className="text-2xl font-semibold hover:underline sm:text-3xl">
                      {expertise.title}
                    </div>
                    <div className="pb-8">{expertise.subtitle}</div>
                  </LocalizedLink>
                ))}
                <hr className="text-white md:mr-4 lg:mr-12" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AllExpertises;
