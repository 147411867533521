import React from 'react';
import { useTranslation } from 'react-i18next';
import SvgDownArrow from '../../images/icons/downArrow.inline.svg';
import useElementOnScreen from '../../utils/useElementOnScreen';

type HeaderProps = {
  expertise: string;
  className: string;
  circleClassName: string;
};

const Header: React.VoidFunctionComponent<HeaderProps> = ({
  expertise,
  className,
  circleClassName,
}) => {
  const { t } = useTranslation('expertises');
  const subtitle = t(`expertises.${expertise}.subtitle`);

  const [animatedElement, isInView] = useElementOnScreen<HTMLDivElement>();

  return (
    <div className="relative">
      <header className={`text-center lg:h-screen ${className}`}>
        <div className="container flex h-full flex-col items-center justify-center px-4 pb-32 pt-24 sm:px-8 lg:pb-16 lg:pt-0">
          <div
            ref={animatedElement}
            className={`translate-y-12 px-4 opacity-0 sm:px-8 lg:-mt-32 ${
              isInView ? 'animate-slidein' : ''
            }`}
          >
            <p className="text-2xl">{t('our-expertise')}</p>
            <h1>{t(`expertises.${expertise}.title`)}</h1>
            {subtitle ? <h2>{subtitle}</h2> : null}
          </div>
        </div>
      </header>
      <div
        className={`absolute -bottom-12 left-1/2 -ml-20 h-40 w-40 rounded-full bg-opacity-70 md:-bottom-16 md:-ml-24 md:h-48 md:w-48 lg:-bottom-32 lg:-ml-48 lg:h-96 lg:w-96 ${circleClassName}`}
      >
        <div className="flex h-full w-full items-center justify-center">
          <a
            className="animate-bounce text-center"
            title={t('discover-our-expertise')}
            href="#definition"
            rel="nofollow"
          >
            <SvgDownArrow className="mx-auto block" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
