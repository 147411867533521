import React from 'react';
import { useTranslation } from 'react-i18next';

type DefinitionProps = {
  expertise: string;
  className: string;
};

const Definition: React.VoidFunctionComponent<DefinitionProps> = ({ expertise, className }) => {
  const { t } = useTranslation('expertises');

  return (
    <section id="definition">
      <div className={`lg:h-screen ${className}`}>
        <div className="container flex h-full max-w-4xl flex-col justify-center px-4 py-8 sm:px-8 lg:py-0">
          <p>{`> ${t('our-expertises')}`}</p>
          <h3 className="mb-6 text-left text-3xl font-bold leading-snug sm:text-4xl">
            {t(`expertises.${expertise}.definition.title`)}
          </h3>
          <p className="text-xl font-medium sm:text-2xl">
            {t(`expertises.${expertise}.definition.text`)}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Definition;
