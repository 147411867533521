import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import { AllImage } from '../../utils/graphql';
import MeetButton from '../buttons/MeetButton';
import { GatsbyImage } from 'gatsby-plugin-image';

type MeetProps = {
  offer: string;
  className: string;
  pseudoClass: string;
};

const Meet: React.VoidFunctionComponent<MeetProps> = ({ offer, className, pseudoClass }) => {
  const {
    allFile: { nodes: teamMemberImages },
  }: { allFile: AllImage } = useStaticQuery(
    graphql`
      {
        allFile(
          filter: { sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: "team" } }
        ) {
          nodes {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 360, placeholder: BLURRED)
            }
            name
          }
        }
      }
    `,
  );

  const { t } = useTranslation('expertises');
  const { t: tOffer } = useTranslation('offers');

  const partnerName = tOffer(`offers.${offer}.meet.partner.name`);
  const partnerImageName = tOffer(`offers.${offer}.meet.partner.photo`);
  const partnerImage = teamMemberImages.find((v) => v.name === partnerImageName);

  return (
    <section id="meet">
      <div className={`lg:h-screen ${className}`}>
        <h3 className="pt-28 text-3xl font-bold leading-snug sm:text-4xl">
          {t('meet-us-title.0')}
          <br />
          {t('meet-us-title.1')}
        </h3>
        <div className="container flex h-7/10 max-w-4xl flex-col justify-center px-4 py-8 sm:px-8">
          <div className="flex flex-wrap items-center justify-center">
            {partnerImage && (
              <div className="md:flex-1/3">
                <GatsbyImage
                  image={partnerImage.childImageSharp.gatsbyImageData}
                  title={partnerName}
                  alt={partnerName}
                />
              </div>
            )}
            <div className="pt-6 sm:pt-8 md:flex-2/3 md:pl-8 md:pt-0 lg:pl-20">
              <p className="text-xl font-medium sm:text-2xl">
                {tOffer(`offers.${offer}.meet.title`)}
              </p>
              <MeetButton
                href={tOffer(`offers.${offer}.meet.bookingUrl`)}
                text={tOffer(`offers.${offer}.meet.button`)}
                pseudoClass={pseudoClass}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Meet;
